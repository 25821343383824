import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { isValidPhoneNumber } from 'libphonenumber-js'

import Customer from "Customer"
import QuickPay from "./QuickPay"
import { CardElement } from '@stripe/react-stripe-js';

import Form from "Form"
import Icon from "Icon"
import Input from "Input"
import Switch from "Switch"
import PhoneInput from 'react-phone-number-input/input'

class PaymentRequired extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      paymentComplete: false
    }
  }

  updateCard = (ev) => this.setState({ paymentComplete: ev.complete })

  canSubmit = () => {
    if (this.state.processing) return false
    const { formData } = this.props
    let phone = (formData.phone ? formData.phone : "")
    if (!this.state.paymentComplete) return false
    return(formData.phone && formData.name && isValidPhoneNumber(formData.phone, "US"))
  }

  handleSuccessfulPayment = (name, phone, email) => {
    const { formData } = this.props
    const cartId = Customer.getCartId()
    let data = {
      name: name,
      email: email,
      phone: phone,
      rewards: formData.rewards || false,
      sectionId: formData.sectionId,
      deliveryInfo: formData.deliveryInfo,
    }

    let req = request.post(`customer/checkout/${cartId}/quickpay`, data)
    req.then(({data}) => this.paymentSuccess(data))
  }

  handleSubmit = (event) => {
    this.setState({paymentError: null, processing: true})
    if (!this.canSubmit()) return false
    event.preventDefault();

    if (!this.props.stripe || !this.props.elements) {
      return;
    }

    const cartId = Customer.getCartId()
    heap.track("Payment/Submit", { cartId: cartId })
    const { stripe, payment, formData, elements } = this.props
    const cardElement = elements.getElement(CardElement);

    let stripePayment = stripe.confirmCardPayment(payment.intentSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
        }
      }
    })

    stripePayment.then((result) => {
      if (result.error) {
        heap.track("Payment/Failed", { cartId: cartId })
        this.setState({paymentError: result.error.message, processing: false})
      }

      if (result.paymentIntent && result.paymentIntent.status == "requires_capture") {
        heap.track("Payment/Success", { cartId: cartId })
        let req = request.post(`customer/checkout/${cartId}/payment`, formData)
        req.then(({data}) => this.paymentSuccess(data))
      }
    })
  }

  paymentSuccess = (data) => {
    Customer.clearCartId()
    this.setState({toOrderPage: true, orderId: data.order.id})
  }

  render() {
    if (this.state.toOrderPage) return <Redirect to={`/orders/${this.state.orderId}`} />

    const { formData, store, payment } = this.props

    return(
      <div className="PaymentRequired mt-4">
        <h3 className="subtitle" style={{marginLeft: -10 }}>
          Payment Information
        </h3>
          <QuickPay
            stripe={this.props.stripe}
            name={formData.name}
            onPaymentSuccess={this.handleSuccessfulPayment}
            storeName={store.name}
            amount={payment.amount}
            clientSecret={payment.intentSecret}
            country={this.props.country}
            currency={this.props.currency}
          />

          <Input
            label="Your Full Name"
            name="full_name"
            onChange={this.props.updateField.bind(null, "name")}
            value={formData.name}
          />
          <Input
            label="Email (Optional)"
            name="email"
            onChange={this.props.updateField.bind(null, "email")}
            value={formData.email}
          />
          <div className="field block">
            <label className="label">Phone Number</label>
            <PhoneInput
              country="US"
              name="phone"
              onChange={this.props.updateField.bind(null, "phone")}
              value={formData.phone}
              className="input"
            />
          </div>

          <div className="field block">
            <label className="label">Payment Info</label>
            <CardElement
              onChange={this.updateCard}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#888',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>

          { this.state.paymentError &&
            <p className="Error notification is-danger is-light">
              Payment Error: { this.state.paymentError }
            </p>
          }

          { this.props.isRewardsEnabled &&
            <div>
              <Switch
                label="Enroll in rewards?"
                onChange={this.props.updateField.bind(null, "rewards")}
                value={formData.rewards || false}
                className="mb-0"
              />
              <p style={{marginTop: -10}} >
                Once enrolled, always rewarded – your phone number remains
                 registered in our rewards program, ensuring you earn points
                 seamlessly with every order.
              </p>
            </div>
          }

          <button
            disabled={!this.canSubmit()}
            onClick={this.handleSubmit}
            className="button is-primary mt-5 is-fullwidth"
          >
            { this.state.processing ? "Processing…" : "Place Order" }
          </button>
      </div>
    )
  }
}

export default usesFeatures(["payments"], PaymentRequired)
