import "./StoreView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Image from "Image"
import Header from "Header"
import Customer from "Customer"
import DrawerModal from "DrawerModal"

import ProductView from "Customer/ProductView"
import CartView from "Customer/CartView"
import Menu from "./Menu.jsx"

class StoreView extends React.Component {
  state = {
    cartId: Customer.getCartId(),
    selectedProduct: null,
    itemsInCart: 0,
    cartStoreId: null,
  }

  componentDidMount() {
    const id = this.props.match.params.storeId
    this.props.fetchData(`/customer/stores/${id}?cartId=${this.state.cartId}`, this.setCart)
  }

  setCart = (data) => {
    if(data.cart) this.setState({itemsInCart: data.cart.itemCount, cartStoreId: data.cart.storeId})
  }

  updateCart = (cartData) => {
    Customer.setCartId(cartData.cartId)
    this.setState({cartId: cartData.cartId, itemsInCart: cartData.itemCount, cartStoreId: cartData.storeId})
    this.clearProduct()
  }

  selectProduct = (selectedProduct) => this.setState({selectedProduct})
  openCart = () => this.setState({cartOpen: true})
  closeCart = () => this.setState({cartOpen: false})

  clearProduct = () => {
    heap.track("Product/Close", { productId: this.state.selectedProduct })
    this.setState({selectedProduct: null})
  }

  render() {
    if (!this.props.dataFetched) return false

    const { store, categories, products, cart } = this.props
    const { selectedProduct, itemsInCart, cartStoreId } = this.state

    return(
      <div className={`StoreView ${itemsInCart > 0 ? "HasCart" : ""}`}>
        <Header
          text={store.name}
          subtitle={ store.closestSection ? `Closest Section - ${store.closestSection}` : null }
          backLink={store.allInclusive ? null : `/${store.vendorAtId || store.venueId}`}
        />

        { !store.open &&
          <div className="message is-danger">
            <div className="message-body">
              Sorry! This store is closed - you will not be able to place an order
            </div>
          </div>
        }

        <div className="Header">
          <Image src={store.imageUrl} ratio="3by2" />
        </div>

        <div className="container">
          <Menu
            isAllInclusive={store.allInclusive}
            categories={categories}
            products={products}
            selectProduct={this.selectProduct}
            currentPath={this.props.location.pathname}
          />
        </div>

        { itemsInCart > 0 && store.id == cartStoreId &&
          <div className="Footer">
            <span onClick={this.openCart} className="ViewCart button h-button is-link is-rounded columns is-fullwidth has-text-weight-bold">
              <div className="column has-text-left">Items in Cart</div>
              <div className="column has-text-right">{ itemsInCart }</div>
            </span>
          </div>
        }

        { selectedProduct &&
          <DrawerModal hasFooter={true} dismiss={this.clearProduct}>
            <ProductView
              isAllInclusive={store.allInclusive}
              productId={selectedProduct}
              updateCart={this.updateCart}
              cartId={this.state.cartId}
            />
          </DrawerModal>
        }
        { this.state.cartOpen &&
          <DrawerModal hasFooter={true} dismiss={this.closeCart}>
            <CartView
              isAllInclusive={store.allInclusive}
              productId={selectedProduct}
              updateCart={this.updateCart}
              cartId={this.state.cartId}
            />
          </DrawerModal>
        }
      </div>
    )
  }
}

export default usesFeatures(["data"], StoreView)
