import React from "react";
import { Link } from "react-router-dom";

import Image from "Image"

export default class Menu extends React.PureComponent {
  productsFor = (category) => {
    return this.props.products.filter((p) => p.categoryId == category.simpleId && p.isActive)
  }

  productLink = (productId) => {
    return(`${this.props.currentPath}/products/${productId}`)
  }

  renderCategory = (category, i) => {
    let products = this.productsFor(category)
    if (products.length == 0) return null
    return(
      <div key={i} className="Category block">
        <h3 className="title is-size-3">{ category.id ? category.name : "Other" }</h3>
        { products.map(this.renderProduct) }
      </div>
    )
  }

  renderProduct = (product, i) => {
    if (!product.isActive) return null
    return(
      <div className="Product list-view-item" key={i} onClick={this.props.selectProduct.bind(null, product.id)}>
        <div className="list-view-item-inner">
          <div className="meta-left">
            <p className="has-text-weight-bold is-size-4">{ product.name }</p>
            { product.description && <p className="block">{ product.description }</p> }
            { !this.props.isAllInclusive && <p className="subtitle is-6 block">${ product.price }</p> }
          </div>
          <Image src={product.imageUrl} ratio="square" />
        </div>
      </div>
    )
  }

  render() {
    const { categories } = this.props

    return(
      <div className="Menu block">
        <div className="list-view list-view-v2">
          <div className="list-view-inner">
            { categories.map(this.renderCategory) }
          </div>
        </div>
      </div>
    )
  }
}

