import "./OrderView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Image from "Image"
import CustomerForm from "./CustomerForm"

class OrderView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const orderId = this.props.match.params.orderId
    this.props.fetchData(`/customer/orders/${orderId}`, this.trackEvent)
  }

  trackEvent = (data) => {
    heap.addUserProperties({accountId: data.accountId});
    heap.track("Order/View", { orderId: data.order.id })
  }

  renderProduct = (product, i) => {
    const { order, customer } = this.props
    return(
      <div key={product.id} className="columns is-mobile is-vcentered">
        <div className="column is-2">
          <Image src={product.imageUrl} ratio="square" />
        </div>
        <div className="column">
          <p className="is-size-5">
            { product.name }
            { product.quantity > 1 ? ` x${product.quantity}` : "" }
          </p>
          { product.fulfilled != product.quantity && (order.status == "complete"  || order.status == "cancelled") &&
            <p>
              Refunded { product.quantity - product.fulfilled }
            </p>
          }
          <p>{ product.modifiers.map((m) => m.name).join(", ") }</p>
          { !this.props.order.allInclusive && <p>${ product.price }</p> }
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { order, customer } = this.props

    if(!customer) return(
      <CustomerForm
        orderId={order.id}
      />
    )

    return(
      <div className="OrderView">
        <div className="s-card container p-4 mb-4">
          <h3 className="title">Your Order</h3>
          <p className="subtitle mb-1">Order#{order.number}: { order.status == "ready" ? "ready for pickup" : order.status}</p>
          <p className="subtitle mb-1">Store: { order.store.name }</p>
          <p className="subtitle mb-1">Confirmation/Pickup Code: {
            [ order.confirmationCode.slice(0, 3), order.confirmationCode.slice(3) ].join('-')
          }</p>
          { order.sectionName &&
            <p className="subtitle">
              Delivering To: { order.sectionName }{ order.location ? ` - ${order.location }` : "" }
            </p>
          }
        </div>
        <div className="s-card container p-4 mb-4">
          <p className="notification is-info is-light">
            You will be receive text message updates for your order, so you know when it's
            { order.sectionName ? " been accepted" : " ready for pickup" }
          </p>
          { order.store.pickupInstructions &&
          <>
            <h3 className="subtitle">Pickup Instructions</h3>
            <p> { order.store.pickupInstructions }</p>
          </>
          }
        </div>
        <div className="mb-4">
          <Link to={`/stores/${order.store.id}`} className="OrderAgain button is-fullwidth is-link">
            Order from {order.store.name} again
          </Link>
        </div>
        { order.isEnrolledInRewards &&
          <div className="s-card container p-4 mt-3 mb-5">
            <h3 className="title">Your Rewards balance: { order.rewardsBalance }</h3>
            <p className="subtitle mt-2">
              { order.nextReward ? (
                <>
                  Earn a discount code for { order.nextReward.rewardDisplay + " "}
                   off your next order when you reach { order.nextReward.points } points
                </>
              ) : (
                <>You've earned all available rewards. Congrats!</>
              )}
            </p>
          </div>
        }
        <div className="s-card container p-4 mb-6">
          <h3 className="title">Items in this order</h3>
          { order.products.map(this.renderProduct) }
          { order.products.length == 0 &&
            <p className="title has-text-centered">Nothing here</p>
          }

          { !order.allInclusive &&
            <div className="has-text-right">
              <p>
                <span className="has-text-weight-bold">Items Subtotal</span>
                <span className="ml-2">${ order.subtotal }</span>
              </p>
              <p>
                <span className="has-text-weight-bold">Sales Tax</span>
                <span className="ml-2">${ order.taxes }</span>
              </p>
              { order.customerFee != "$0.00" &&
                <p>
                  <span className="has-text-weight-bold">{ order.account.customerFeeDescriptor }</span>
                  <span className="ml-2">{ order.customerFee }</span>
                </p>
              }
              { order.tip > 0 &&
                <p>
                  <span className="has-text-weight-bold">Tip</span>
                  <span className="ml-2">${ order.tip.toFixed(2) }</span>
                </p>
              }
              { order.discountAmount != "$0.00" &&
                <p className="has-text-success-dark">
                  <span className="has-text-weight-bold">{ order.discount.name }</span>
                  <span className="ml-2">{ order.discountAmount }</span>
                </p>
              }
              <p>
                <span className="has-text-weight-bold">Total</span>
                <span className="ml-2">${ order.chargeTotal }</span>
              </p>
              { order.refunded &&
                <p>
                  <span className="has-text-weight-bold">Refunded</span>
                  <span className="ml-2">${ order.refunded }</span>
                </p>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data'], OrderView)
