import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { startCase } from "lodash"

import Image from "Image"

const ReceiptView = () => {
  const [order, setOrder] = useState({})
  const [customer, setCustomer] = useState({})
  const { orderId } = useParams()

  const fetchOrder = () => {
    const req = request.get(`/customer/orders/${orderId}`)
    req.then(({data}) => {
      setOrder(data.order)
      setCustomer(data.customer)
    })
  }
  useEffect(fetchOrder, [])

  const renderProduct = (product, i) => {
    return(
      <div key={product.id} className="columns is-mobile is-vcentered">
        <div className="column is-2">
          <Image src={product.imageUrl} ratio="square" />
        </div>
        <div className="column">
          <p className="is-size-5">
            { product.name }
            { product.quantity > 1 ? ` x${product.quantity}` : "" }
          </p>
          { product.fulfilled != product.quantity && (order.status == "complete"  || order.status == "cancelled") &&
            <p>
              Refunded { product.quantity - product.fulfilled }
            </p>
          }
          <p>{ product.modifiers.map((m) => m.name).join(", ") }</p>
          { !order.allInclusive && <p>${ product.price }</p> }
        </div>
      </div>
    )
  }

  if(!order.id) return null
  return(
    <div className="OrderView">
      <div className="s-card container p-4 mb-4">
        <h3 className="title">Receipt for Order</h3>
        <p className="subtitle mb-2">Order#{order.number}: { order.status == "ready" ? "ready for pickup" : startCase(order.status)}</p>
        <p className="subtitle mb-2">Store: { order.store.name }</p>
        { order.type === "mobile" &&
          <p className="subtitle mb-2">Confirmation/Pickup Code: {
            [ order.confirmationCode.slice(0, 3), order.confirmationCode.slice(3) ].join('-')
          }</p>
        }
        { order.sectionName &&
          <p className="subtitle mb-2">
            Delivering To: { order.sectionName }{ order.location ? ` - ${order.location }` : "" }
          </p>
        }
        <p className="subtitle mb-2">
          Paid via { startCase(order.payment.type) }
        </p>
      </div>
      <div className="s-card container p-4 mb-6">
        <h3 className="title">Items in this order</h3>
        { order.products.map(renderProduct) }
        { order.products.length == 0 &&
          <p className="title has-text-centered">Nothing here</p>
        }

        { !order.allInclusive &&
          <div className="has-text-right">
            <p>
              <span className="has-text-weight-bold">Items Subtotal</span>
              <span className="ml-2">${ order.subtotal }</span>
            </p>
            <p>
              <span className="has-text-weight-bold">Sales Tax</span>
              <span className="ml-2">${ order.taxes }</span>
            </p>
            { order.customerFee != "$0.00" &&
              <p>
                <span className="has-text-weight-bold">{ order.account.customerFeeDescriptor }</span>
                <span className="ml-2">{ order.customerFee }</span>
              </p>
            }
            { order.tip > 0 &&
              <p>
                <span className="has-text-weight-bold">Tip</span>
                <span className="ml-2">${ order.tip.toFixed(2) }</span>
              </p>
            }
            { order.discountAmount != "$0.00" &&
              <p className="has-text-success-dark">
                <span className="has-text-weight-bold">{order.discount.name}</span>
                <span className="ml-2">{ order.discountAmount }</span>
              </p>
            }
            <p>
              <span className="has-text-weight-bold">Total</span>
              <span className="ml-2">${ order.chargeTotal }</span>
            </p>
            { order.refunded &&
              <p>
                <span className="has-text-weight-bold">Refunded</span>
                <span className="ml-2">${ order.refunded }</span>
              </p>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default ReceiptView
