import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { isValidPhoneNumber } from 'libphonenumber-js'

import SeatSelector from "SeatSelector"

export default class OrderMethod extends React.PureComponent {
  changeOrderMethod = (type) => {
    if (type != this.props.formData.method) {
      heap.track("Checkout/Method", { method: type})
      this.props.updateField("method", type)
      this.props.updateField("sectionId", null)
      this.props.updateField("deliveryInfo", null)
    }
  }

  selectSeat = (data) => {
    this.setState({...data})

    if (data.selectedSeat) {
      this.props.updateField("sectionId", data.selectedSection)
      this.props.updateField("deliveryInfo", { row: data.selectedRow, seat: data.selectedSeat })
    } else if (data.selectedSection && data.sectionOnly) {
      this.props.updateField("sectionId", data.selectedSection)
      this.props.updateField("deliveryInfo", {})
    } else {
      this.props.updateField("deliveryInfo", null)
    }
  }

  render() {
    const { formData, store, payment, sections } = this.props
    return(
      <div>
        { !this.props.hideMethod &&
          <div>
            <h3 className="subtitle" style={{marginLeft: -10 }}>
              Select Order Method
            </h3>
            <div className="field has-addons button-group columns mb-4">
              <p className="control column p-0 is-half">
                <span
                  className={`button is-fullwidth is-link has-text-weight-bold ${ formData.method == "pickup" ? "" : "is-outlined" }`}
                  onClick={this.changeOrderMethod.bind(null, "pickup")}
                >
                  Pickup
                </span>
              </p>
              <p className="control column is-half p-0">
                <span
                  className={`button is-fullwidth is-link has-text-weight-bold ${ formData.method == "delivery" ? "" : "is-outlined" }`}
                  onClick={this.changeOrderMethod.bind(null, "delivery")}
                >
                  Delivery
                </span>
              </p>
            </div>
          </div>
        }
        { formData.method == "delivery" &&
          <div>
            { this.props.hideMethod && <h2 className="title is-4 mb-5">Delivery</h2> }
            <h3 className="subtitle mb-2">Where are you located?</h3>
            <SeatSelector
              className="is-flex"
              sections={this.props.sections}
              depth="seat"
              onChange={this.selectSeat}
            />
            { !formData.deliveryInfo &&
              <p className="message is-warning p-2 mt-4 has-text-weight-bold">
                If your section and seat are not listed, your order
                 is not eligible for delivery from { store.name }
              </p>
            }
          </div>
        }
      </div>
    )
  }
}
