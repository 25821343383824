import "./Checkout.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { isValidPhoneNumber } from 'libphonenumber-js'
import { FeatureFlagContext } from "FeatureFlagContext"

import Customer from "Customer"

import Form from "Form"
import Icon from "Icon"
import Input from "Input"
import PhoneInput from 'react-phone-number-input/input'

import NewCheckout from "./NewCheckout"

import PaymentRequired from "./components/PaymentRequired"
import AllInclusive from "./components/AllInclusive"
import OrderMethod from "./components/OrderMethod"

class Checkout extends React.PureComponent {
  static contextType = FeatureFlagContext

  constructor (props) {
    super(props)
    this.state = {
      paymentComplete: false
    }
  }

  componentDidMount() {
    const cartId = Customer.getCartId()
    heap.track("Checkout/Start", { cartId: cartId })
    this.props.fetchData(`customer/checkout/${cartId}/start`, this.setOrderType)
  }

  setOrderType = (data) => {
    // All Inclusive Delivery
    if (data.store.delivery && data.store.allInclusive) {
      this.props.updateField("method", "delivery")
      this.setState({hideMethod: true})
    }
    // No Delivery
    else if (data.sections.length == 0 || data.store.delivery == false) {
      this.setState({hideMethod: true})
      this.props.updateField("method", "pickup")
    }
    // Delivery only
    else if (!data.store.pickup && data.store.delivery) {
      this.setState({hideMethod: true})
      this.props.updateField("method", "delivery")
    }
  }

  readyForPayment = () => {
    if (this.props.formData.method == "pickup") return true

    return this.props.formData.method == "delivery" && this.props.formData.deliveryInfo
  }

  render() {
    if (!this.props.dataFetched) return false

    const { formData, store, payment, sections } = this.props

    return(
      <div className="Checkout">
        <div className="CheckoutWrapper">
          <div className="Header columns is-mobile is-vcentered">
            <div className="column is-narrow pl-4">
              <Link to={`/stores/${store.id}`}><Icon icon="chevron-left" className="is-size-4"/></Link>
            </div>
            <div className="column has-text-centered">
              <p className="is-size-6">Checkout from:</p>
              <p className="is-size-4">{ this.props.store.name }</p>
            </div>
            <div className="column is-narrow"/>
          </div>
          <Form>
            <OrderMethod { ...this.props } hideMethod={this.state.hideMethod} />

            { this.readyForPayment() && (
                this.context.isEnabled("saved_payment_methods") ? (
                store.allInclusive ? <AllInclusive { ...this.props} /> : <NewCheckout { ...this.props } />
              ) : (

              <div className="mt-4">
                { store.allInclusive ?
                  <AllInclusive { ...this.props} /> : (
                    <PaymentRequired
                      isRewardsEnabled={this.props.venue.rewardsProgram && this.context.isEnabled("rewards")}
                      { ...this.props }
                    />
                  )
                }
              </div>
              ))}
          </Form>
        </div>
        <div className="Terms">
          <p className="has-text-grey-light has-text-centered is-size-6 has-text-weight-medium">
            By placing an order you agree to our&nbsp;
            <a className="has-text-grey-light is-underlined" href="/privacy-policy" target="_blank">Privacy Policy</a>
          </p>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], Checkout)
