
import React from "react";
import { Link, Redirect } from "react-router-dom";

import Image from "Image"

export default class Store extends React.PureComponent {
  render() {
    const { store } = this.props
    return(
      <Link key={store.id} to={`/stores/${store.id}`} className="Store is-relative list-view-item">
        { !store.open && <div className="Closed">Store Closed</div> }
        <div className="list-view-item-inner">
          <div className="meta-left">
            <h3>{ store.name }</h3>
            { store.closestSection &&
              <p>Closest Section - { store.closestSection }</p>
            }
            <p> { store.description }</p>
          </div>
          <Image src={store.imageUrl} ratio="square" />
        </div>
      </Link>
    )
  }
}
