import "./ProductView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { xor, intersection, sum } from "lodash"

import Icon from "Icon"
import Image from "Image"
import QuantityInput from "QuantityInput"

class ProductView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      quantity: 1,
      selectedModifiers: []
    }
  }

  componentDidMount() {
    heap.track("Product/View", { productId: this.props.productId })
    this.props.fetchData(`customer/products/${this.props.productId}`, this.lockScroll)
  }

  updateQuantity = (quantity) => {
    heap.track("Product/Quantity", { quantity: quantity })
    this.setState({quantity})
  }

  handleAddToCart = () => {
    let params = {
      cartId: this.props.cartId,
      productId: this.props.productId,
      selectedModifiers: this.state.selectedModifiers,
      quantity: this.state.quantity,
    }

    heap.track("Cart/Add", { productId: params.productId, quantity: params.quantity, cartId: params.cartId })

    let req = request.post("customer/cart/add", params)
    req.then(({data}) => {
      this.props.updateCart(data)
      this.props.closeModal()
    })
  }

  toggleModifier = (id) => {
    this.setState(prevState => {
      let selectedModifiers = xor(prevState.selectedModifiers, [ id ])
      return { selectedModifiers };
    })
  }

  totalPrice = () => {
    const { product } = this.props
    let prices = this.state.selectedModifiers.map((e) => parseFloat(product.modifiers.find((x) => x.id == e).price))
    return (this.state.quantity * (parseFloat(product.price) + sum(prices))).toFixed(2)
  }

  renderModifier = (modifier, key) => {
    if (!modifier.active) return null

    return(
      <div key={key} className="ml-2 my-1 columns is-mobile Modifier" onClick={this.toggleModifier.bind(null, modifier.id)}>
        <div className="column is-narrow Checkbox">
          <Icon icon={ this.state.selectedModifiers.indexOf(modifier.id) > -1 ? "check-square" : "square" } />
        </div>
        <div className="column Name">
          <p className="is-size-6">{ modifier.name }</p>
        </div>
        <div className="column is-narrow Price">
          { modifier.price != 0 &&
            (parseFloat(modifier.price) >= 0 ? "+" : "-") + "$" + modifier.price
          }
        </div>
      </div>
    )
  }

  canAddProduct = () => {
    const { selectedModifiers } = this.state
    const { modifierGroups, modifiers } = this.props.product

    return modifierGroups.every((group) => {
      const groupSelections = intersection(selectedModifiers, group.modifierIds).length

      return (
        // Optional Groups: Is there no max, or are selections under it
        (!group.required && (!group.max || groupSelections <= group.max)) ||
        // Required groups: Is there a selection, and are the selections under the max
        (group.required && groupSelections > 0 && (!group.max || groupSelections <= group.max))
      )
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { product } = this.props

    return(
      <div className="ProductView">
        <Image src={product.imageUrl} ratio="3by2" className="block" />

        <div className="block">
          <p className="title block">{ product.name }</p>
          <p className="">{ product.description }</p>
        </div>
        <div className="block">
          { product.modifierGroups.length > 0 ? (
              product.modifierGroups.map((mg, i) => {
                return(
                  <div key={"group-" + i} className="mb-4">
                    { mg.name &&
                      <div className="mb-2 columns is-mobile">
                        <p className="column subtitle mb-0">
                        { mg.name }
                        { mg.max &&
                          <>
                            <br/>
                            <span className="inline-block is-size-6">
                              Select { mg.max > 1 && "Up To " }{ mg.max }
                            </span>
                          </>
                        }
                        </p>
                        { mg.required &&
                          <div className="column is-narrow">
                            <span className="tag is-warning">
                              <Icon icon="exclamation-triangle" className="mr-1" />
                              Required
                            </span>
                          </div>
                        }
                      </div>
                    }
                    { mg.modifiers.map((m, mi) => this.renderModifier(m, mi)) }
                  </div>
                )
              })
          ) : (
            product.modifiers.map((m, i) => this.renderModifier(m, i) )
          )}

        </div>
        <div className="block">
          <QuantityInput
            onChange={this.updateQuantity}
            value={this.state.quantity}
          />
        </div>

        <div className="Footer has-text-centered">
          <button
            onTouchEnd={this.handleAddtoCart}
            onClick={this.handleAddToCart}
            disabled={!this.canAddProduct()}
            className="AddToCart button is-link is-rounded has-text-weight-bold"
          >
            <span className="has-text-left">Add to Cart</span>
            { !this.props.isAllInclusive && <span className="has-text-right ml-3">${ this.totalPrice() }</span>}
          </button>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data'], ProductView)
