import "./Checkout.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { isValidPhoneNumber } from 'libphonenumber-js'

import Customer from "Customer"

import Form from "Form"
import Icon from "Icon"
import Input from "Input"
import PhoneInput from 'react-phone-number-input/input'

import PaymentRequired from "./components/PaymentRequired"
import AllInclusive from "./components/AllInclusive"
import OrderMethod from "./components/OrderMethod"

class Checkout extends React.PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      checkoutStage: "info",
      paymentComplete: false,
      processing: false,
    }
  }

  readyForPayment = () => {
    if (this.props.formData.method == "pickup") return true

    return this.props.formData.method == "delivery" && this.props.formData.deliveryInfo
  }

  canProgress = () => {
    const { checkoutStage } = this.state
    const { formData } = this.props

    if(checkoutStage == "info" && isValidPhoneNumber((formData.phone || ""), "US")) return true
  }

  nextStep = () => {
    switch(this.state.checkoutStage) {
      case "info": this.submitCustomer()
    }
  }

  submitCustomer = () => {
    const { phone } = this.props.formData
    const req = request.post("customer/customers", { phone })
    req.then(({data}) => {
      if (data.success) {
      } else {
      }
    })
  }

  render() {
    const { formData, store, payment, sections } = this.props
    const { checkoutStage } = this.state
    console.log(payment)
    return(
      <div className="NewCheckout">

        <div className="field block">
          <label className="label">Phone Number</label>
          <PhoneInput
            country="US"
            name="phone"
            onChange={this.props.updateField.bind(null, "phone")}
            value={formData.phone}
            className="input"
          />
        </div>

        <div className="is-flex mt-6">
          { checkoutStage == "info" &&
            <button className="button is-outline">Back</button>
          }
          <button disabled={!this.canProgress()} onClick={this.nextStep} className="button is-primary ml-auto">Next</button>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], Checkout)

