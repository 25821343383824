import "./RadioButtonGroup.scss";

import React from "react";

export default class RadioButtonGroup extends React.PureComponent {
  state = {}

  classesFor = (button) => {
    const classes = [ "button", "h-button" ]

    if (button == this.props.value.toFixed()) {
      classes.push("is-primary")
    }

    return classes.join(" ")
  }

  handleClick = (value) => {
    this.props.onChange(value)
  }

  render() {
    return(
      <div className={`RadioButtonGroup ${this.props.className}`}>
        { this.props.label &&
          <p className="subtitle"> {this.props.label} </p>
        }
        <div className="field has-addons">
          { Object.entries(this.props.options).map(([value, display], i) => {
            return(
              <p className="control" key={value}>
                <span onClick={this.handleClick.bind(null, value)} className={this.classesFor(value)}>
                  { display }
                </span>
              </p>
            )
          })}
        </div>
      </div>
    )
  }
}
