import "./QuantityInput.scss";

import React from "react";
import Icon from "Icon"

export default class QuantityInput extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  canSubtract = () => this.props.value > 1

  updateValue = (direction) => {
    let value = this.props.value + direction
    if (value < 1) return
    this.props.onChange(value)
  }

  render() {
    const { value } = this.props
    return(
      <div className="QuantityInput">
        <div className={`Action ${value == 1 ? "disabled" : ""}`} onClick={this.updateValue.bind(null, -1)}>
          <Icon icon="minus" />
        </div>
        <div className="Value">{ value }</div>
        <div className="Action" onClick={this.updateValue.bind(null, 1)}>
          <Icon icon="plus" />
        </div>
      </div>
    )
  }
}
