import "./VenueDirectory.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Store from "./Store.jsx"

class VenueDirectory extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const id = this.props.match.params.venueId
    this.props.fetchData(`customer/venue/${id}`)
    heap.track("Venue/View", { venueId: id })
  }

  renderStore = (store) => <Store key={store.id} store={store} />

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="VenueDirectory">
        <div className="list-view list-view-v2">
          <div className="list-view-inner">
            { this.props.stores.map(this.renderStore) }
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], VenueDirectory)
