import "./DrawerModal.scss";

import React from "react";
import Swipeable from "Swipeable"
import Icon from "Icon"

export default class DrawerModal extends React.PureComponent {
  constructor (props) {
    super(props)
    this.drawer = React.createRef()
    this.state = {
      expanded: false
    }
  }

  componentDidMount() {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';

    setTimeout(() => {
      this.drawer.current.className += " expanded"
    }, 100)
  }

  componentWillUnmount() {
    const scrollY = document.body.style.top;
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  handleSwipe = (swipe) => {
    if (swipe.velocity > 0.6) {
      heap.track("Product/SwipeClose")
      this.close()
    }
  }

  close = () => {
    if (this.drawer.current) { this.drawer.current.className += " closing" }
    setTimeout(this.props.dismiss, 300)
  }

  render() {
    return(
      <div ref={this.drawer} className={`DrawerModal modal is-active ${this.props.hasFooter ? "HasFooter" : ""}`}>
        <span className="Close" aria-label="close" onClick={this.close}>
          <Icon icon="times" />
        </span>
        <div className={`drawer-content`}>
          <Swipeable onSwipedDown={this.handleSwipe} className="Swiper">
            {
              React.Children.map(this.props.children, (child) => {
                return React.cloneElement(child, {
                  closeModal: this.close,
                });
              })
            }
          </Swipeable>
        </div>
      </div>
    )
  }
}
