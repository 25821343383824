import React from 'react'
import { render } from "react-dom";
// Images (jpg, jpeg, png) are imported  here.
// This will copy all static images under `frontend/images` to the output
// folder and reference them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'hero.png' %>) or the `imagePath` JavaScript helper
// below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import CustomerProviderWrapper from 'CustomerContext';
import { I18nextProvider } from "react-i18next";
import i18n from "lib/i18n"

import Customer from "routes/Customer";
import ErrorReporting from "ErrorReporting";

document.addEventListener("DOMContentLoaded", () => {
  render(
    <ErrorReporting>
      <CustomerProviderWrapper>
        <I18nextProvider i18n={i18n}>
          <Customer />
        </I18nextProvider>
      </CustomerProviderWrapper>
    </ErrorReporting>,
    document.getElementById("app")
  );
});

