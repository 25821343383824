import "./Swipeable.scss";

import React from "react";

import { useSwipeable } from "react-swipeable"

export const Swipeable = ({children, ...props}) => {
  const handlers = useSwipeable(props);
  return (<div className={props.className} { ...handlers }>{children}</div>);
}

export default Swipeable
