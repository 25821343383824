const KEYS = {
  cartId: "catchCustomerCartId",
}

const Customer = {
  setCartId: (data) => localStorage.setItem(KEYS.cartId, data),
  getCartId: () => localStorage.getItem(KEYS.cartId),
  clearCartId: () => localStorage.removeItem(KEYS.cartId),
}

export default Customer
