import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { isValidPhoneNumber } from 'libphonenumber-js'

import Card from "Card"
import Input from "Input"
import PhoneInput from 'react-phone-number-input/input'

class CustomerForm extends React.Component {
  state = {
    errors: []
  }

  handleSubmit = () => {
    if (!this.canSubmit()) return false

    this.setState({processing: true, errors: []})
    const { name, email, phone } = this.props.formData

    let req = request.put(`/customer/orders/${this.props.orderId}`, { name, email, phone })
    req.then(({data}) => {
      this.setState({processing: false})
      if (data.success) {
        window.location.reload()
      } else {
        this.setState({errors: data.errors})
      }
    })
  }

  canSubmit = () => {
    if (this.state.processing) return false
    const { formData } = this.props
    let phone = (formData.phone ? formData.phone : "")
    return(formData.phone && formData.name && isValidPhoneNumber(formData.phone, "US"))
  }

  render() {
    const { formData, updateField } = this.props
    return(
      <div className="CustomerForm">
        <div className="columns is-mobile is-vcentered is-centered">
          <div className="column is-narrow pl-4">
            <Card>
              <div className="subtitle">
                <p>Oh no! Looks like we had some issues gathering your contact info.</p>
                <p className="mt-2">
                  Your Order will be placed once this form is submitted
                </p>
              </div>
              { this.state.errors.length > 0 &&
                <div className="message is-danger p-2">
                  { this.state.errors.map((e, i) => <p key={i}>{e}</p>) }
                </div>
              }
              <Input label="Name" onChange={updateField.bind(null, "name")} value={formData.name} />
              <Input label="Email (optional)" onChange={updateField.bind(null, "email")} value={formData.email} />
              <div className="field block">
                <label className="label">Phone Number</label>
                <PhoneInput
                  country="US"
                  name="phone"
                  onChange={this.props.updateField.bind(null, "phone")}
                  value={formData.phone}
                  className="input"
                />
              </div>

              <button
                disabled={!this.canSubmit()}
                onClick={this.handleSubmit}
                className="button is-primary mt-2"
              >
                { this.state.processing ? "Processing…" : "Update Info" }
              </button>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['form'], CustomerForm)
