import "./CartView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import Customer from "Customer"
import Flash from "flash"

import Icon from "Icon"
import Image from "Image"
import Input from "Input"
import Header from "Header"
import Button from "Button"
import RadioButtonGroup from "RadioButtonGroup"

class CartView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      discountCode: ""
    }
  }

  componentDidMount() {
    const cartId = Customer.getCartId()
    this.props.fetchData(`customer/cart/${cartId}`)
    heap.track("Cart/View", { cartId: cartId })
  }

  removeProduct = (lineItemId) => {
    const cartId = Customer.getCartId()
    heap.track("Cart/Remove ", { cartId: cartId })
    const params = {
      cartId: cartId,
      lineItemId: lineItemId,
    }

    let req = request.post("customer/cart/remove", params)
    req.then(({data}) => {
      if (data.success) {
        this.props.updateWith(data.cart)
        this.props.updateCart(data.cart)
      }
    })
  }

  updateTip = (tipAmount) => {
    heap.track("Cart/Tip", { amount: tipAmount })
    const cartId = Customer.getCartId()
    let req = request.post("customer/cart/tip", { tipAmount, cartId })
    req.then(({data}) => {
      if (data.success) this.props.updateWith(data.cart)
    })
  }

  removeDiscount = () => {
    this.setState({discountLoading: true})
    const cartId = Customer.getCartId()

    let req = request.delete(`carts/${cartId}/discounts`, {})

    req.catch((e) => {
      this.setState({discountErrors: e.response.data.errors})
    })
    req.then(({data}) => {
      this.props.updateWith(data.cart)
      Flash.broadcast("success", "Discount Removed")
    })
    req.finally(() => {
      this.setState({discountLoading: false})
    })
  }
  submitDiscount = () => {
    this.setState({discountLoading: true})
    const cartId = Customer.getCartId()
    let req = request.post(`carts/${cartId}/discounts`, {
      code: this.state.discountCode
    })
    req.catch((e) => {
      this.setState({discountErrors: e.response.data.errors})
    })
    req.then(({data}) => {
      this.props.updateWith(data.cart)
      this.setState({discountCode: ""})
      Flash.broadcast("success", `Discount ${data.cart.discount.name} applied!`)
    })
    req.finally(() => {
      this.setState({discountLoading: false})
    })
  }

  renderProduct = (product, i) => {
    return(
      <div key={product.id} className="Product columns is-mobile is-vcentered">
        <div className="Image column is-2">
          <span className="Quantity"> { product.quantity} </span>
          <Image src={product.imageUrl} ratio="square" />
        </div>
        <div className="column pl-5">
          <p>{ product.name }</p>
          <p>{ product.modifiers.map((m) => m.name).join(", ") }</p>
          { !this.props.isAllInclusive && <p>${ product.price }</p>}
        </div>
        <div className="column is-2">
        </div>
        <div className="column is-narrow pr-2 pl-2">
          <Icon icon="trash-alt" onClick={this.removeProduct.bind(null, product.id)} className="is-size-4"/>
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="CartView">
        <div className="container">
          <Header
            text={`Your order from ${this.props.store.name}`}
          />

          { this.props.products.map(this.renderProduct) }
          { this.props.products.length == 0 &&
            <p className="title has-text-centered">Nothing here</p>
          }

          <div className="block has-text-centered">
            <span onClick={this.props.closeModal} className="button is-link is-rounded">Add more items</span>
          </div>

          { this.props.isShowDiscount &&
            <div className="block has-text-centered">
              { this.props.discount &&
                <Button
                  text="Remove Discount"
                  loading={this.state.discountSubmitting}
                  className="mx-auto"
                  onClick={this.removeDiscount}
                />
              }
              { !this.props.discount &&
                <>
                  <div className="is-flex is-justify-content-center has-text-centered">
                    <Input
                      name="Discount Code"
                      onChange={(v) => this.setState({discountCode: v})}
                      value={this.state.discountCode}
                      className="is-flex-grow"
                    />
                    <Button
                      text="Apply Discount"
                      loading={this.state.discountSubmitting}
                      className="ml-4"
                      onClick={this.submitDiscount}
                      disabled={this.state.discountCode == ""}
                    />
                  </div>
                  { this.state.discountErrors &&
                    <p className="text-danger has-text-centered">{ this.state.discountErrors.join(", ") }</p>
                  }
                  <p className="has-text-centered mt-0">Have a discount code? Apply it here</p>
                </>
              }
            </div>
          }
          { !this.props.isAllInclusive && this.props.store.open &&
            <div className="block has-text-centered">
              <RadioButtonGroup
                options={this.props.tipOptions}
                className="has-text-centered"
                label="Add a tip?"
                value={this.props.tip * 100}
                onChange={this.updateTip}
              />
            </div>
          }
          { !this.props.isAllInclusive &&
            <div className="has-text-right">
              <p>
                <span className="has-text-weight-bold">Items Subtotal</span>
                <span className="ml-2">${ this.props.subtotal }</span>
              </p>
              <p>
                <span className="has-text-weight-bold">Sales Tax</span>
                <span className="ml-2">${ this.props.taxes }</span>
              </p>
              { this.props.customerFee != "$0.00" &&
                <p>
                  <span className="has-text-weight-bold">{ this.props.account.customerFeeDescriptor }</span>
                  <span className="ml-2">{ this.props.customerFee }</span>
                </p>
              }
              { this.props.discountAmount != "$0.00" &&
                <p className="has-text-success-dark">
                  <span className="has-text-weight-bold">{this.props.discount.name}</span>
                  <span className="ml-2">{ this.props.discountAmount }</span>
                </p>
              }
              { this.props.tip > 0 &&
                <p>
                  <span className="has-text-weight-bold">Tip</span>
                  <span className="ml-2">${ this.props.tip.toFixed(2) }</span>
                </p>
              }
              <p>
                <span className="has-text-weight-bold">Total</span>
                <span className="ml-2">${ this.props.chargeTotal }</span>
              </p>
              { this.props.venue.cartDisclaimer &&
                <p className="mt-4">{ this.props.venue.cartDisclaimer }</p>
              }
            </div>
          }
        </div>
        { this.props.itemCount > 0 && this.props.store.open &&
          <div className="Footer has-text-centered">
            <Link to="/checkout" className="Continue button is-link is-rounded has-text-weight-bold">
              <span className="has-text-left">Continue</span>
              { !this.props.isAllInclusive &&
                <span className="has-text-right ml-4">${ this.props.chargeTotal }</span>
              }
            </Link>
          </div>
        }
      </div>
    )
  }
}

export default usesFeatures(['data'], CartView)
