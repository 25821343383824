import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';

const QuickPay = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: props.country,
        currency: props.currency.toLowerCase(),
        total: {
          label: `${props.storeName} via Catch`,
          amount: props.amount,
        },
        requestPayerName: true,
        requestPayerPhone: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (ev) => {
        heap.track("Payment/Submit", { quickPay: true })
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
          props.clientSecret,
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
        );

        if (confirmError) {
          heap.track("Payment/Fail", { quickPay: true })
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          heap.track("Payment/Success", { quickPay: true })
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const {error} = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded.
            }
          } else {
            // The payment has succeeded.
            props.onPaymentSuccess(ev.payerName, ev.payerPhone, ev.payerEmail)
          }
        }
      });
    }
  }, [stripe]);


  return(
    <>
      { paymentRequest &&
        <div className="mb-4">
          <h3 className="title is-5 mt-3">Express Pay</h3>
          <PaymentRequestButtonElement className="QuickPay" options={{paymentRequest}} />
        </div>
      }
    </>
  )

  // Use a traditional checkout form.
  return 'Insert your form or button component here.';
}

export default QuickPay
