import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { isValidPhoneNumber } from "libphonenumber-js"

import Customer from "Customer"
import QuickPay from "./QuickPay"
import { CardElement } from "@stripe/react-stripe-js";

import Form from "Form"
import Icon from "Icon"
import Input from "Input"
import PhoneInput from "react-phone-number-input/input"
import SeatSelector from "SeatSelector"

export default class AllInclusive extends React.PureComponent {
  state = {
    processing: false
  }

  canSubmit = () => {
    if (this.state.processing) return false
    const { formData } = this.props
    let phone = (formData.phone ? formData.phone : "")
    return(
      formData.phone && formData.name &&
      isValidPhoneNumber(formData.phone, "US") &&
      (formData.sectionId || formData.method == "pickup")
    )
  }

  selectSeat = (data) => {
    this.setState({...data})
  }

  handleSubmit = () => {
    if (!this.canSubmit()) return false
    this.setState({processing: true})

    const { name, phone, location, sectionId, deliveryInfo } = this.props.formData
    const data = { name, phone, location, sectionId, deliveryInfo }
    const cartId = Customer.getCartId()

    let req = request.post(`customer/checkout/${cartId}/inclusive`, data)
    req.then(({data}) => {
      if (data.success) {
        Customer.clearCartId()
        this.setState({toOrderPage: true, orderId: data.order.id})
      } else {
        this.setState({processing: false, errors: data.errors})
      }
    })
    req.catch(() => { this.setState({processing: false, errors: "There was a problem. Please try again shortly."}) })
  }

  render() {
    const { formData, store, payment } = this.props

    if (this.state.toOrderPage) return <Redirect to={`/orders/${this.state.orderId}`} />

    return(
      <div className="AllInclusive">
          <Input
            label="Your Full Name"
            onChange={this.props.updateField.bind(null, "name")}
            value={formData.name}
          />
          <Input
            label="Email (Optional)"
            onChange={this.props.updateField.bind(null, "email")}
            value={formData.email}
          />
          <div className="field block">
            <label className="label">Phone Number</label>
            <PhoneInput
              country="US"
              onChange={this.props.updateField.bind(null, "phone")}
              value={formData.phone}
              className="input"
            />
          </div>

          { this.state.errors &&
            <p className="Error notification is-danger is-light mt-4">
              { this.state.errors }
            </p>
          }

          <button
            disabled={!this.canSubmit()}
            onClick={this.handleSubmit}
            className="button is-primary mt-4"
          >
            { this.state.processing ? "Processing…" : "Place Order" }
          </button>
      </div>
    )
  }
}
