import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import wrapComponent from "ApplicationWrapper"

import CartView from "Customer/CartView";
import StoreView from "Customer/StoreView";
import OrderView from "Customer/OrderView";
import Checkout from "Customer/Checkout";
import VenueDirectory from "Customer/VenueDirectory";
import ReceiptView from "Customer/ReceiptView";

import FourOhFour from "FourOhFour"

export default props => (
  <Router>
    <Switch>
      <Route path="/cart" exact component={wrapComponent(CartView, "Customer")} />
      <Route path="/checkout" exact component={wrapComponent(Checkout, "Customer")} />

      <Route path="/stores/:storeId" exact component={wrapComponent(StoreView, "Customer")} />
      <Route path="/:venueId" exact component={wrapComponent(VenueDirectory, "Customer")} />


      <Route path="/o/:orderId" exact component={wrapComponent(OrderView, "Customer")} />
      <Route path="/orders/:orderId" exact component={wrapComponent(OrderView, "Customer")} />

      <Route path="/r/:orderId" exact component={wrapComponent(ReceiptView, "Customer")} />

      <Route path="/*" component={wrapComponent(FourOhFour)} />
    </Switch>
  </Router>
);
